import Ripples from "react-ripples";
import limi from "../Components/imgs/profile.webp";
import { motion } from "framer-motion";

// Framer Motion props
const titleVariant = {
  hidden: {
    opacity: 0,
    y: "20vh",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      duration: 6,
    },
  },
};
// End of Framer Motion

function Home(props) {
  const { isDarkMode, t } = props;

  function scrollA() {
    document.getElementById("about").scrollIntoView({ behavior: "smooth" });
  }
  function scrollC() {
    document.getElementById("contact").scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      <div className="hidden lg:block absolute font-sans font-bold dark:text-white text-black blur-[2px] z-0">
        <motion.div
          drag
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: 0.1,
            duration: 2,
            repeat: Infinity,
            repeatType: "mirror",
          }}
          className="absolute left-60 top-64 m-[15rem]"
        >
          11
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: -2,
            duration: 3,
            repeat: Infinity,
            repeatType: "mirror",
          }}
          className="absolute  m-[36rem] z-20"
        >
          101
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: 0.3,
            duration: 3,
            repeat: Infinity,
            repeatType: "mirror",
          }}
          className="absolute left-80 ml-80 "
        >
          111
        </motion.div>
        <motion.div
          drag
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: -0.45,
            duration: 3,
            repeat: Infinity,
            repeatType: "mirror",
          }}
          className="absolute left-20 bottom-0 m-24"
        >
          001
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: 0.07,
            duration: 3,
            repeat: Infinity,
            repeatType: "mirror",
          }}
          className="absolute top-40 m-[15rem]"
        >
          1
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: -8,
            duration: 2,
            repeat: Infinity,
            repeatType: "mirror",
          }}
          className="absolute left-56 bottom-45 m-52"
        >
          10
        </motion.div>
      </div>
      <div className="text-[#0e161c] dark:text-lightGrey h-[80vh] flex lg:justify-between justify-center">
        <motion.div variants={titleVariant} initial="hidden" animate="visible">
          <div className="lg:pt-20 lg:pl-36">
            <h1 className="text-[1.8em] md:text-[2.5em] font-semibold text-center lg:text-start">
              {t("home_header")}
            </h1>
            <p className="text-xl md:text-2xl font-semibold text-center lg:text-start">
              {t("home_header2")}
            </p>
          </div>

          <div className="p-4 lg:pl-32 lg:pt-16">
            <p className="text-center lg:text-start pl-5 w-[80vw] lg:w-[100%]">
              {t("home_text")}
            </p>
            <div className="flex flex-col items-center text-center pt-10 lg:pt-5 lg:pl-5 gap-6 lg:flex lg:justify-start lg:flex-row">
              <Ripples
                onClick={scrollA}
                color={`${isDarkMode ? "#00000036" : "#ffffff80"}`}
                className="transition hover:scale-105"
              >
                <button className="w-36 p-2 border-2 whitespace-nowrap bg-[#0e161c] border-[#0e161c] text-lightGrey dark:text-[#0e161c] dark:bg-lightGrey dark:border-lightGrey dark:font-semibold">
                  {t("home_learn_btn")}
                </button>
              </Ripples>
              <Ripples
                onClick={scrollC}
                color={`${isDarkMode ? "#ffffff80" : "#00000036"}`}
                className="transition hover:scale-105"
              >
                <button className="w-36 p-2 border-2 border-[#0e161c] text-[#0e161c] dark:text-lightGrey dark:bg-transparent dark:border-lightGrey font-semibold dark:font-normal">
                  {t("home_contact_btn")}
                </button>
              </Ripples>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ type: "spring", duration: 5, delay: 0.2 }}
          className="hidden lg:flex justify-center items-center w-[100%] bg-limibgw dark:bg-limibgb bg-center bg-no-repeat mt-[-15vh] drop-shadow-2xl z-10"
        >
          <img
            src={limi}
            alt="profileImg"
            className="lg:flex rounded-bl-[8.5em] rounded-br-[6em] object-cover h-[30em] mt-[-1em] drop-shadow-2xl"
          />
        </motion.div>
      </div>
    </>
  );
}
export default Home;
