import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import sq from "./locales/sq.json";
import en from "./locales/en.json";

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    sq: {
      translation: sq,
    },
  },
  fallbackLng: "en",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
