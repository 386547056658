import React, { useState } from "react";
import { SlArrowUp } from "react-icons/sl";
import { motion, AnimatePresence } from "framer-motion";
import logow from "./imgs/logow.png";
import logob from "./imgs/logob.png";

export default function Footer(props) {
  const { isDarkMode } = props;

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  const date = new Date();
  const year = date.getFullYear();

  return (
    <>
      <AnimatePresence>
        {visible && (
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={scrollToTop}
            className="bg-transparent text-3xl bottom-10 right-7 fixed lg:right-14 lg:bottom-12 text-[#0e161c] dark:text-lightGrey"
          >
            <SlArrowUp className="stroke-[2em]" />
          </motion.button>
        )}
      </AnimatePresence>

      <div className="mt-32 text-[#0e161c] dark:text-lightGrey flex justify-center">
        <p className="flex flex-row">
          <a href="/">
            <img
              src={isDarkMode ? `${logow}` : `${logob}`}
              alt="l"
              width={20}
            />
          </a>{" "}
          &copy; {year}.
        </p>
      </div>
    </>
  );
}
