import React from "react";
import "./index.css";
import Index from "./Pages/Index";
import Error from "./Pages/Error";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Routes>
        <Route path="*" element={<Error />} />
        <Route path="/" element={<Index />} />
      </Routes>
    </>
  );
}

export default App;
