import Ripples from "react-ripples";
import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { FaFacebookF, FaLinkedinIn, FaPaperPlane } from "react-icons/fa";
import { BsInstagram, BsGithub } from "react-icons/bs";
import { MdOutlineLocationOn } from "react-icons/md";
import { motion } from "framer-motion";

const onScrollView = {
  hidden: {
    y: "20vh",
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export default function Contact({ t }) {
  const [formData, setFormData] = React.useState({
    fullName: "",
    email: "",
    message: "",
  });

  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }

  const [sent, setSent] = React.useState(false);

  // ------------------ EmailJS
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_4l0zudq",
        "template_6d1wlab",
        form.current,
        "3xp2UXxDA3gBIKc3T"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    setSent((current) => !current);
    setFormData({ fullName: "", email: "", message: "" });
    setTimeout(() => {
      setSent((current) => !current);
    }, 2000);
  };

  // -------------------- Github API
  const [data, setData] = useState(null);

  // {name: "Lirim Avdyli", login: "lirimav", location: "Prishtine", public_repos: 5}

  useEffect(() => {
    fetch("https://api.github.com/users/lirimav")
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <div
        className="text-[#0e161c] dark:text-lightGrey pb-16 lg:pb-28 
      text-[1.5em] text-center lg:text-start lg:pl-36"
      >
        <h1 id="contact" className="font-semibold -z-20 relative">
          {t("contact_header")}
        </h1>
      </div>

      <div
        className="text-[#0e161c] dark:text-lightGrey flex flex-col-reverse items-center lg:flex-row lg:justify-evenly lg:pt-10 lg:pb-28 
      lg:dark:bg-planeb lg:bg-planew lg:bg-center lg:bg-contain dark:bg-planebmb bg-planewmb bg-no-repeat bg-contain"
      >
        <motion.div
          variants={onScrollView}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0.5 }}
          className="bg-[#ffffffd4] dark:bg-[#0f1419c9] shadow-lightM dark:shadow-darkM"
        >
          <a href="https://github.com/lirimav" target="_blank" rel="noreferrer">
            <div className="p-10 flex justify-center items-center">
              {data ? (
                <div className="flex flex-col lg:flex-row justify-center items-center text-center lg:text-start gap-10">
                  <motion.img
                    whileHover={{ scale: 1.1 }}
                    src={`${data.avatar_url}`}
                    alt=""
                    className="h-28 w-28 rounded-full shadow-lightM dark:shadow-darkM"
                  ></motion.img>
                  <div className="flex flex-col gap-2">
                    <BsGithub className="text-[1.5em] absolute left-4 top-6" />
                    <h1 className="text-[1.2em]">{data.name}</h1>
                    <p className="text-gray-500">{data.login}</p>
                    <p>Repos: {data.public_repos}</p>
                    <p className="flex flex-row items-center gap-2">
                      <MdOutlineLocationOn className="text-xl" />
                      {data.location}
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <motion.div
                    animate={{ rotate: 180 }}
                    transition={{
                      repeat: Infinity,
                      ease: "linear",
                      duration: 1,
                    }}
                    className="border-y-2 rounded-full w-20 h-20"
                  ></motion.div>
                </div>
              )}
            </div>
          </a>

          <a
            href="https://www.linkedin.com/in/lirimavdyli/"
            className="flex items-center justify-center p-2 transition-all duration-300 bg-gray-100 dark:bg-[#0f1419c9] dark:hover:bg-[#0a66c2] hover:text-white hover:bg-[#0a66c2]"
          >
            <FaLinkedinIn className="pr-4 text-4xl absolute left-6" />
            <span className="font-semibold text-[1.2em] lg:text-[1.3em]">
              LinkedIn
            </span>
          </a>

          <a
            href="https://www.instagram.com/lirimavdyli"
            className="flex items-center justify-center p-2 transition-all duration-200 bg-gray-100 dark:bg-[#0f1419c9] hover:bg-gradient-to-tr from-[#fec564] via-[#d1547c] to-[#8c3ebe] hover:text-white"
          >
            <BsInstagram className="pr-4 text-4xl absolute left-6" />
            <span className="font-semibold text-[1.2em] lg:text-[1.3em]">
              Instagram
            </span>
          </a>

          <a
            href="https://facebook.com/lirimav"
            className="flex items-center justify-center p-2 transition-all duration-300 bg-gray-100 dark:bg-[#0f1419c9] dark:hover:bg-[#4867aa] hover:text-white hover:bg-[#4867aa]"
          >
            <FaFacebookF className="pr-4 text-3xl absolute left-6" />
            <span className="font-semibold text-[1.2em] lg:text-[1.3em]">
              Facebook
            </span>
          </a>
        </motion.div>

        <motion.div
          variants={onScrollView}
          initial="hidden"
          whileInView={{ opacity: 1, y: 0, rotate: 90 }}
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0.5 }}
          className="border w-16 mt-20 mb-20 lg:hidden dark:border-white border-black"
        ></motion.div>

        {/* --------- Contact Form */}
        <motion.form
          variants={onScrollView}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1 }}
          className="flex flex-col gap-2 w-96 p-10 bg-[#ffffffd4] dark:bg-[#0f1419c9] shadow-lightM dark:shadow-darkM"
          ref={form}
          onSubmit={sendEmail}
        >
          <h1 className="text-[1.3em] font-semibold mb-5">
            {t("contact_form_header")}
          </h1>
          <input
            required
            type="text"
            placeholder={t("contact_form_name")}
            onChange={handleChange}
            name="fullName"
            value={formData.fullName}
            id="fname"
            className="border-b-2 border-[#0e161c] dark:border-white bg-transparent h-12 transition duration-1000 dark:text-white text-[#0e161c]
              focus:outline-none placeholder:font-extrabold	placeholder:transition placeholder:duration-1000
              focus:placeholder:opacity-0	focus:placeholder:transition focus:placeholder:duration-1000 rounded-none"
          />
          <input
            required
            type="email"
            placeholder={t("contact_form_email")}
            onChange={handleChange}
            name="email"
            value={formData.email}
            id="email"
            className="border-0 border-b-2 border-[#0e161c] dark:border-white bg-transparent h-12 transition duration-1000 dark:text-white text-[#0e161c] 
              focus:outline-none placeholder:font-extrabold	placeholder:transition placeholder:duration-1000
              focus:placeholder:opacity-0	focus:placeholder:transition focus:placeholder:duration-1000 rounded-none"
          />
          <textarea
            required
            placeholder={t("contact_form_message")}
            onChange={handleChange}
            name="message"
            value={formData.message}
            maxLength={1000}
            style={{ maxWidth: "140%", maxHeight: "10em", minHeight: "5em" }}
            id="message"
            className="border-0 border-b-2 border-[#0e161c] dark:border-white bg-transparent transition duration-300 pt-4 dark:text-white text-[#0e161c]
               focus:outline-none placeholder:font-extrabold	placeholder:transition placeholder:duration-1000
               focus:placeholder:opacity-0	focus:placeholder:transition focus:placeholder:duration-1000 rounded-none"
          />

          <Ripples
            className="btn flex justify-center self-center border-2 border-[#0e161c] dark:border-white 
            p-[11px] cursor-pointer overflow-hidden bg-transparent mt-5 w-[150px] hover:bg-[#0e161c] hover:dark:bg-lightGrey
            hover:text-white dark:hover:text-[#0e161c] hover:transition-all hover:duration-400"
          >
            <button id="submitbtn">
              <FaPaperPlane
                className={`sarrow ${sent && "sarrowN"}`}
                aria-hidden="true"
              />
              <FaPaperPlane
                className={`text-3xl barrow ${sent && "barrowN"}`}
                aria-hidden="true"
              />
              <p
                className={`uppercase text-center text-[20px] font-[#0e161c] ml-[30px] ${
                  sent && "pF"
                }`}
              >
                {t("contact_form_sendBtn")}
              </p>
            </button>
          </Ripples>
        </motion.form>
      </div>
    </>
  );
}
