import { Link } from "react-scroll";
import { RiSunFill } from "react-icons/ri";
import { BsMoonStarsFill } from "react-icons/bs";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";

const svgVariants = {
  hidden: {
    rotate: -90,
  },
  visible: {
    rotate: 0,
    transition: {
      duration: 1,
    },
  },
};

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    fill: "none",
    pathLength: 0.4,
    transition: {
      duration: 5,
      ease: "easeInOut",
    },
  },
};

const menuAnimation = {
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const linkVariants = {
  hidden: { x: 300, opacity: 0 },
  visible: (i) => ({
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      delay: i * 0.2,
    },
  }),
  exit: (i) => ({
    x: 300,
    opacity: 0,
    transition: {
      duration: 1.5,
      delay: -i * 0.05,
    },
  }),
};

function NavBar(props) {
  const { open, setOpen, toggleDarkMode, isDarkMode, changeLanguage, t } =
    props;

  const [lng, setLng] = useState("en");

  const handleLanguageChange = () => {
    const newLng = lng === "sq" ? "en" : "sq";
    setLng(newLng);
    changeLanguage(newLng);
  };

  const navbarRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex items-center justify-between pt-8 px-8 h-40 lg:h-[25vh]">
        <div className="logo">
          {/* logo */}
          <a href="/">
            <motion.svg
              version="1.1"
              viewBox="0 0 640 640"
              variants={svgVariants}
              initial="hidden"
              animate="visible"
              className="w-[90px] h-[90px] md:w-[120px] md:h-[120px]"
            >
              <defs></defs>
              <motion.path
                variants={pathVariants}
                transform="matrix(1 0 0 1 254.58 291.25)"
                stroke={`${isDarkMode ? "white" : "#0E161C"}`}
                strokeWidth="3px"
                vectorEffect="non-scaling-stroke"
                d="M -56.88312 82.5974 L 10.389609999999998 -82.59741 L 56.88312 33.76623000000001 L 36.62338 82.59740000000001 z"
                strokeLinecap="round"
              />
              <motion.path
                variants={pathVariants}
                transform="matrix(1 0 0 1 419.84 366.77)"
                stroke={`${isDarkMode ? "white" : "#0E161C"}`}
                strokeWidth="3px"
                vectorEffect="non-scaling-stroke"
                d="M 133.1281 215.10696 L 38.33982999999999 215.10696 L -133.12810000000002 -215.10695 L -39.76317000000002 -215.10695 z"
                strokeLinecap="round"
              />
              <motion.path
                variants={pathVariants}
                transform="matrix(1 0 0 1 231.2 274.62)"
                stroke={`${isDarkMode ? "white" : "#0E161C"}`}
                strokeWidth="3px"
                vectorEffect="non-scaling-stroke"
                d="M 144.76558 215.27419 L 111.28553 126.34995 L -56.13377 126.34995 L -56.13377 -85.40603999999999 L -144.76558 -215.27419 L -144.76558 215.27419999999998 z"
                strokeLinecap="round"
              />
            </motion.svg>
          </a>
        </div>
        {/* logo */}

        <div className="text-[#0e161c] dark:text-lightGrey z-20">
          <div className="z-10 hidden lg:flex flex-row justify-center items-center tracking-wider font-[600] gap-6 pr-10">
            <label
              htmlFor="toggle"
              className="flex items-center duration-300 ease-in-out cursor-pointer text-2xl"
            >
              {!isDarkMode ? (
                <BsMoonStarsFill className="animate__animated animate__zoomIn " />
              ) : (
                <RiSunFill className="animate__animated animate__zoomIn text-white" />
              )}
            </label>
            <input
              id="toggle"
              type="checkbox"
              className="hidden"
              value="darkMode"
              onClick={toggleDarkMode}
            />
            <button
              className="cursor-pointer text-xl"
              onClick={handleLanguageChange}
            >
              {lng === "en" ? "AL" : "EN"}
            </button>
          </div>

          <div
            className="flex flex-row justify-center items-center gap-6 mr-6 lg:hidden"
            ref={navbarRef}
          >
            <div className="flex items-center justify-center space-x-2 z-10">
              <label
                htmlFor="toggle"
                className="flex items-center duration-300 ease-in-out cursor-pointer text-2xl"
              >
                {!isDarkMode ? (
                  <BsMoonStarsFill className="animate__animated animate__zoomIn " />
                ) : (
                  <RiSunFill className="animate__animated animate__zoomIn text-white" />
                )}
              </label>
              <input
                id="toggle"
                type="checkbox"
                className="hidden"
                value="darkMode"
                onClick={toggleDarkMode}
              />
            </div>
            <div>
              <button
                onClick={() => setOpen((prev) => !prev)}
                id="menu-btn"
                className={`${
                  open && "open"
                } z-10 block hamburger lg:hidden focus:outline-none outline-none`}
              >
                <span className="hamburger-top bg-[#0e161c] dark:bg-white"></span>
                <span className="hamburger-middle bg-[#0e161c]  dark:bg-white"></span>
                <span className="hamburger-bottom bg-[#0e161c] dark:bg-white"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="h-14">
        <AnimatePresence>
          {open && (
            <div className="lg:hidden flex justify-center items-center">
              <motion.div
                variants={menuAnimation}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="flex flex-row text-center gap-4 tracking-wider font-[600] text-lg text-[#0e161c] dark:text-white"
              >
                <motion.div variants={linkVariants} custom={0}>
                  <Link
                    className="cursor-pointer"
                    ignoreCancelEvents={true}
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={1000}
                  >
                    {t("nav_about")}
                  </Link>
                </motion.div>

                {/* <motion.div variants={linkVariants} custom={1}>
                  <Link
                    className="cursor-pointer"
                    ignoreCancelEvents={true}
                    to="projects"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={1000}
                  >
                    {t("nav_projects")}
                  </Link>
                </motion.div> */}
                <motion.div variants={linkVariants} custom={2}>
                  <Link
                    className="cursor-pointer"
                    ignoreCancelEvents={true}
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={1000}
                  >
                    {t("nav_contact")}
                  </Link>
                </motion.div>
                <motion.div
                  variants={linkVariants}
                  custom={3}
                  className="cursor-pointer"
                >
                  <button onClick={handleLanguageChange}>
                    {lng === "en" ? "AL" : "EN"}
                  </button>
                </motion.div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
}

export default NavBar;
